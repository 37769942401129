import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { addPrepometerData, getPrepometerData, updatePrepometerStatus } from "../../services/prepometer";
import { getSpayeeUserCourses } from "../../services/backlog";

const PrepometerContext = createContext({});

const PrepometerProvider = ({ children, spayee_id }) => {
    const [subjects, setSubjectsData] = useState([]);
    const [activeSubject, setActiveSubject] = useState(0);
    const [isFiltered, setIsFiltered] = useState(false);
    const [user, setUser] = useState({
        user_id: null,
        spayee_course_phase_id: null,
        current_subcourse: null,
        spayee_id: spayee_id
    });

    async function fetchUser() {
        getSpayeeUserCourses(spayee_id)
            .then(res => {
                const data = res.data?.data[0];
                if (!data) throw "User not found!"
                const { user_id, spayee_course_phase_id, current_subcourse } = data;
                setUser({ user_id, spayee_course_phase_id, current_subcourse });
            }).catch(alert)
    }

    async function fetchData() {
        const { user_id, spayee_course_phase_id } = user;
        await getPrepometerData(user_id, spayee_course_phase_id)
            .then(prepometerData => {
                setSubjectsData(prepometerData);
            }).catch(alert)
    }

    useEffect(() => {
        fetchUser()
    }, []);

    useEffect(() => {
        if (user.user_id)
            fetchData();
    }, [user])

    const updateItem = useCallback((itm) => {
        updatePrepometerStatus(user.user_id, itm.prepometer_chapter_item_id,
            itm.status,
            itm.is_hidden,
            itm.deadline).then(fetchData).catch(console.log);
    }, [user]);

    const updateChapterStatus = useCallback((idArr) => {
        addPrepometerData(user.user_id, idArr).then((res) => {
            fetchData()
        });
    }, [activeSubject, user.user_id]);

    // const updateProgress = useCallback((name, checked, assetName) => {
    // setSubjectsData(subjects => subjects.map(subject => {
    //     if (subject.id === activeSubject + 1) {
    //         const chapters = subject.chapters.map(chapter => {
    //             if (chapter.name === name) {
    //                 const items = chapter.items.map(item => {
    //                     if (item.name === assetName) {
    //                         return { ...item, status: checked ? 1 : 0 };
    //                     }
    //                     return item;
    //                 });
    //                 const progress = Math.ceil((1 / items.length) * 100);
    //                 const chapterProgress = items.reduce((total, item) => total + (item.status === 1 ? progress : 0), 0);
    //                 return {
    //                     ...chapter,
    //                     items,
    //                     progress: Math.min(chapterProgress, 100),
    //                 };
    //             }
    //             return chapter;
    //         });
    //         return { ...subject, chapters };
    //     }
    //     return subject;
    // }));
    // }, [activeSubject]);

    const getData = useMemo(() => {
        if (isFiltered) {
            return subjects.map(subject => ({
                ...subject,
                chapters: subject.chapters.map(chapter => ({
                    ...chapter,
                    items: chapter.items.filter(item => item.status == 0),
                })),
            }));
        }
        return subjects;
    }, [isFiltered, subjects]);

    const updateSubIndex = useCallback((name) => {
        const index = subjects.findIndex(subject => subject.display_name === name);
        setActiveSubject(index);
    }, [subjects]);

    const value = useMemo(() => ({
        subjects: getData,
        updateChapterStatus,
        updateSubIndex,
        isFiltered,
        activeSubject,
        setIsFiltered,
        updateItem,
    }), [getData, updateItem, updateChapterStatus, updateSubIndex, isFiltered, activeSubject, setIsFiltered]);

    return (
        <PrepometerContext.Provider value={value}>
            {children}
        </PrepometerContext.Provider>
    );
};

export default PrepometerProvider;
export const usePrepometer = () => useContext(PrepometerContext);

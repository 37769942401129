import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import UpdateBacklog from "./pages/backlogManager/manage.backlog";
import BacklogManager from "./pages/backlogManager/manager.backlog";
import TotalBacklog from "./pages/backlogManager/totalbacklog.backlog";
import Prepometer from "./pages/prepometer";
import DeadlineIndex from "./pages/prepometer/deadline.index";
import UserAttendance from "./pages/attendance/";
import AddChapters from "./pages/backlogManager/addchapters";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={"user/backlogs/:crm_id"} element={<UpdateBacklog />} />
				<Route path={"user/add-backlog-chapters"} element={<AddChapters />} />
				<Route path={"user/backlog-manager"} element={<BacklogManager />} />
				<Route path={"user/total-backlogs"} element={<TotalBacklog />} />

				<Route path={"user/prepometer/:spayee_id"} element={<Prepometer />} />
				<Route path={"user/targets/"} element={<DeadlineIndex />} />
				<Route path={"user/attendance/:spayee_id"} element={<UserAttendance />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;

import { axiosClient } from "../helpers/axios-client"



export async function getPrepometerData(user_id, spayee_id) {
    return axiosClient.get(`/v1/mentorship/get-prepometer-data?user_id=${user_id}&spayee_course_phase_id=${spayee_id}`).then(res => {
        if (res.data.success) {
            return res.data.data
        } else {
            throw res.data.errors.join(", ")
        }
    })
}


export async function addPrepometerData(user_id, chapter_node_link_ids) {
    return axiosClient.post("/v1/mentorship/add-prepometer-chapter", {
        user_id,
        chapter_node_link_ids
    }).then(res => {
        if (res.data.success) {
            return res.data.data
        } else {
            throw res.data.errors.join(", ")
        }
    })

}

export async function updatePrepometerStatus(user_id, prepometer_chapter_item_id,
    status,
    is_hidden,
    deadline) {
    return axiosClient.put("/v1/mentorship/update-prepometer-item-status", {
        user_id,
        status,
        is_hidden,
        deadline,
        prepometer_chapter_item_id
    }).then(res => {
        if (res.data.success) {
            return res.data.data
        } else {
            throw res.data.errors.join(", ")
        }
    })
}

import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { usePrepometer } from "./context.prepometer";
import PrepometerSubject from "./subject.prepometer";

const subjectOrder = {
	Physics: 0,
	Chemistry: 1,
	Maths: 2,
	Biology: 3,
};

const PropemeterTabs = () => {
	const { subjects, updateSubIndex } = usePrepometer();
	if (!subjects) return null;

	// Sort subjects--> Move PCMB in first place
	subjects
		?.filter((sub) => !!sub)
		?.forEach(({ display_name }, i) => {
			let index = subjectOrder[display_name];
			if (index != undefined && index != i) {
				[subjects[i], subjects[index]] = [subjects[index], subjects[i]];
			}
		});

	return (
		<>
			<Tabs
				defaultActiveKey={subjects[0]?.display_name}
				id="uncontrolled-tab-example"
				className="mobile-tabs-container"
				onSelect={updateSubIndex}
				variant="underline"
				fill
				justify
			>
				{subjects.map(({ display_name, node_id, chapters }) => {
					const availableChapters = chapters.filter((ch) => ch.items.length > 0).length;
					return (
						<Tab eventKey={display_name} title={`${display_name} (${availableChapters})`} key={node_id}>
							<PrepometerSubject chapters={chapters} id={node_id} />
						</Tab>
					);
				})}
			</Tabs>
		</>
	);
};

export default PropemeterTabs;

import { axiosClient } from "../helpers/axios-client"


export async function getUserAttendanceStatus(userId) {
    return axiosClient.get("/v1/mentorship/user-prepo-status?user_id=" + userId).then(res => res.data).catch(err => {
        console.log(err)
        return null;
    })
}


export async function updateUserAttendanceStatus(user_id, message, date) {
    return axiosClient.post("/v1/mentorship/update-user-prepo-status", {
        user_id,
        message,
        date,
    }).then(res => {
        if (res.data.success) {
            return res.data.data
        } else {
            throw res.data.errors.join(", ")
        }
    })
}
import React, { useEffect, useState } from "react";
import { RiCheckboxCircleFill, RiCheckboxCircleLine } from "react-icons/ri";
import { motion } from "framer-motion";

const CustomCheckbox = ({ className = "col-2", onCheck, value }) => {
	const [checked, setChecked] = useState(value);

	const handleCheck = () => {
		setChecked(!checked);
		onCheck(!checked);
	};

	const iconVariants = {
		unchecked: { scale: 0.9, transition: { type: "spring", stiffness: 500, damping: 20 } },
		checked: { scale: 1, transition: { type: "spring", stiffness: 500, damping: 20 } },
	};

	useEffect(()=> {
		setChecked(value)
	},[value])

	return (
		<>
			{checked ? (
				<motion.div
					className={className}
					onClick={handleCheck}
					initial="unchecked"
					animate="checked"
					exit="unchecked"
					variants={iconVariants}
				>
					<RiCheckboxCircleFill color={"#66bb6a"} size={22} />
				</motion.div>
			) : (
				<motion.div
					className={className}
					onClick={handleCheck}
					initial="checked"
					animate="unchecked"
					exit="checked"
					variants={iconVariants}
				>
					<RiCheckboxCircleLine size={22} />
				</motion.div>
			)}
		</>
	);
};

export default CustomCheckbox;

import React, { useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { Button, Offcanvas } from "react-bootstrap";
import { usePrepometer } from "./context.prepometer";
import NewButton from "../../components/NewButton";

const PrepometerMenu = ({ assets }) => {
	const [show, setShow] = useState(false);
	const { updateItem } = usePrepometer();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(!show);

	const handleSkip = (itm) => {
		updateItem({ ...itm, status: -1 });
	};
	const handleAdd = (itm) => {
		updateItem({ ...itm, status: 0, is_hidden: false });
	};

	return (
		<div className="float-end col-1">
			<CiMenuKebab className={"float-end"} onClick={handleShow} />
			<Offcanvas placement="bottom" show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Skip/Add Item</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<ul className="p-2">
						{assets.map((itm, i) => (
							<li
								className="row m-2 justify-content-center align-items-center"
								key={itm.prepometer_chapter_item_id}
							>
								<span className="col-6">{itm.name}</span>
								{!itm.is_hidden && itm.status != -1 ? (
									<Button
										key={`${i}skip`}
										variant={"outline-danger"}
										className="col-4"
										onClick={() => handleSkip(itm)}
									>
										Skip
									</Button>
								) : (
									<NewButton key={`${i}add`} className="col-4 py-1" onClick={() => handleAdd(itm)}>
										Add
									</NewButton>
								)}
							</li>
						))}
					</ul>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default PrepometerMenu;

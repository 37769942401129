import React, { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CustomCheckbox from "../../components/common/CustomCheckbox";
import { usePrepometer } from "./context.prepometer";
import Lottie from "lottie-react";
import celebrationArr from "../../utils/images/lottie/celebration";
import { LuCalendarDays } from "react-icons/lu";
import { AnimatePresence, motion } from "framer-motion";

export const formatDateInDDMM = (d) =>
	new Date(d).toLocaleDateString("en-IN").split("/").slice(0, 2).join("/");

const variants = {
	hidden: { y: 400, opacity: 0, backgroundColor: "none" },
	visible: { y: 0, opacity: 1, backgroundColor: "none" },
};

const PrepoMeterItem = ({ name, chapter, isFirst, isCompleted, deadline, ...props }) => {
	const { updateItem } = usePrepometer();
	const [date, setDate] = useState(deadline);
	const [isVisible, setIsVisible] = useState(false);
	const [checked, setChecked] = useState(isCompleted);
	const [anim, setAnim] = useState(false);
	const [animIndex, setAnimIndex] = useState(null);

	const onDateSelect = useCallback(
		(e) => {
			const dateInMillis = new Date(e);
			const dateInSeconds = dateInMillis / 1000;
			updateItem({
				...props,
				status: checked ? 1 : 0,
				deadline: dateInSeconds,
			});
			setDate(e);
			setIsVisible(false);
		},
		[updateItem, checked]
	);

	const onAnimationComplete = useCallback(() => {
		setAnim(false);
	}, []);

	const onCheckClick = useCallback(
		(isChecked) => {
			updateItem({
				...props,
				status: isChecked ? 1 : 0,
			});
			setChecked(() => isChecked);
			if (isChecked) {
				setAnimIndex(Math.floor(Math.random() * celebrationArr.length));
				setAnim(true);
			}
		},
		[checked]
	);

	useEffect(() => {
		if (typeof deadline === "number") {
			setDate(deadline * 1000);
		}
	}, []);

	const textColor = checked ? " text-success" : "";
	const classes = {
		title: (isFirst ? "col-9 " : "col-6 ") + textColor,
		date: isFirst ? "col-2 ps-2" : "col-4",
		check: (isFirst ? "col-1 custom-check" : "col-2") + " float-end ms-auto",
	};

	return (
		<motion.div
			layout
			transition={{ duration: 0.4 }}
			animation={{
				opacity: 1,
				scale: 1,
			}}
			variants={{
				exit: {
					position: "absolute",
					scaleY: 0,
					opacity: 0,
				},
			}}
			className="rounded-1 align-content-center border border-1 bg-white"
			style={{
				width: isFirst ? "99%" : "49%",
				borderColor: "#F5F5DC",
				padding: 5,
				color: props.color || "",
			}}
			exit="exit"
		>
			{anim && animIndex != null && (
				<div className="lootie-div" key={props.prepometer_chapter_item_id}>
					<Lottie
						className="lootie-main"
						animationData={celebrationArr[animIndex]}
						onLoopComplete={onAnimationComplete}
						onClick={onAnimationComplete}
					/>
				</div>
			)}
			<div className={`d-flex align-items-center`}>
				<span className={classes.title}>{name}</span>
				<AnimatePresence>
					{isVisible && (
						<motion.div
							transition={{ duration: 0.2, bounce: 0.4 }}
							variants={variants}
							initial="hidden"
							animate="visible"
							exit="hidden"
							className="custom-calendar"
							onClick={(e) => {
								if (e.target?.className == "custom-calendar") {
									setIsVisible(false);
								}
							}}
						>
							<Calendar
								className={"text-dark"}
								minDate={new Date(Date.now())}
								onChange={onDateSelect}
								value={date}
							/>
						</motion.div>
					)}
				</AnimatePresence>
				{!checked &&
					(!date ? (
						<LuCalendarDays className={classes.date} onClick={() => setIsVisible(!isVisible)} />
					) : (
						<span onClick={() => setIsVisible(!isVisible)} className={classes.date}>
							{formatDateInDDMM(date)}
						</span>
					))}
				<CustomCheckbox
					key={name}
					className={classes.check}
					value={isCompleted}
					onCheck={onCheckClick}
				/>
			</div>
		</motion.div>
	);
};
export default PrepoMeterItem;

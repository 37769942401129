import React, { useEffect, useRef, useState } from "react";
import Header from "./backlog.header";
import { addUserChapters, get_subject_chapter, getBacklogData } from "../../services/backlog";
import { useLocation, useNavigate } from "react-router-dom";
import NewButton from "../../components/NewButton";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Col, Row } from "react-bootstrap";
import CustomCheckbox from "../../components/common/CustomCheckbox";





const ChapterSelector = ({ chapter, spayeeCoursePhaseId, userId }) => {
    const [checked, setChecked] = useState(false);
    const onChange = (checked) => {
        const isDeleted = !checked
        setChecked(checked)
        console.log(checked, { spayeeCoursePhaseId, userId }, chapter.node_link_id)
        addUserChapters(userId, chapter.node_link_id, spayeeCoursePhaseId, isDeleted)
    }

    return (
        <Row className={`chapter-selector mx-auto py-2 px-3`} onClick={()=> onChange(!checked)}>
            <Col xs="auto" className="d-flex align-items-center">
                <CustomCheckbox
                    value={checked}
                    onCheck={onChange}
                />
            </Col>
            <Col xs className="text-start d-flex align-items-center">
                <label className="chapter-label" style={{
                    color: !checked ? "" : "#66bb6a"
                }}>
                    {chapter.display_name}
                </label>
            </Col>
        </Row>
    )
}




const AddChapters = ({ }) => {
    const { state } = useLocation()
    const [allSubjectsData, setAllSubjectsData] = useState([])
    const [backlogData, setBacklogData] = useState({})
    const [activeIndex, setActiveIndex] = useState(0)
    const navigate = useNavigate()

    const navigateToManager = () => {
        navigate("/user/backlog-manager", {
            state: {...state },
            replace: true
        })
    }
    const onBackClick = () => {
        if (activeIndex !== 0) {
            setActiveIndex(i => --i);
        }
    }
    const onNextClick = () => {
        if (activeIndex < allSubjectsData.length - 1) {
            setActiveIndex(i => ++i);
        } else {
            navigateToManager()
        }
    }

    useEffect(() => {
        get_subject_chapter(state.spayeeCoursePhaseId)
            .then(res => {
                console.log(res)
                getBacklogData(state.userId, state.courseId, state.phaseId)
                    .then(data => {
                        setAllSubjectsData(res);
                        let backlogChapters = {}
                        for (let i of data) {
                            backlogChapters[i.node_link_id] = i
                        }
                        setBacklogData(backlogChapters)
                    })
            })
            .catch(console.error)
    }, [])


    return (
        <div className="flex-container">
            <header className="backlog-header"><Header title="Add Chapters" /></header>
            <main className="backlog-main">

                {allSubjectsData.map((sub, index) => {
                    return (
                        <div key={sub.id} style={{ display: index === activeIndex ? "block" : "none" }}>
                            <h3>{sub.display_name}</h3>
                            {sub.chapters.map((chap, ind) => {

                                return (
                                    !backlogData[chap["node_link_id"]] && <ChapterSelector key={chap.id} chapter={chap} spayeeCoursePhaseId={state.spayeeCoursePhaseId} userId={state.userId} />
                                )
                            })}
                        </div>
                    )

                })}
            </main>

            <footer className="backlog-footer">

                {activeIndex !== 0 && <NewButton className="new-btn-outline" onClick={onBackClick}>
                    <FiChevronLeft size={24} /> Back
                </NewButton>
                }

                <NewButton style={{ marginLeft: 10 }} className="new-btn-outline" onClick={onNextClick}>
                    Next <FiChevronRight size={24} />
                </NewButton>
            </footer>
        </div>
    )
}

export default AddChapters;
import { useEffect, useRef, useState } from "react";
import { getUserAttendanceStatus, updateUserAttendanceStatus } from "../../services/attendance";
import Header from "../backlogManager/backlog.header";
import { useParams } from "react-router-dom";
import { getSpayeeUserCourses } from "../../services/backlog";
import { AnimatePresence, motion } from "framer-motion";
import Calendar from "react-calendar";
import { LuCalendarDays } from "react-icons/lu";
import { IoSend } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { formatDateInDDMM } from "../prepometer/item.prepometer";

const isSameDay = (date1, date2) => {
	const d1 = new Date(date1);
	const d2 = new Date(date2 || Date.now());
	return (
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	);
};

const variants = {
	hidden: { y: 400, opacity: 0, backgroundColor: "none" },
	visible: { y: 0, opacity: 1, backgroundColor: "none" },
};

const getInputHeight = (message) => {
	if (!message) return 1;
	if (message.indexOf("\n") >= 0) {
		return message.split("\n").length;
	}
	if (message.length > 40) return Math.round(message.length / 40);
	return 1;
};

const UserAttendance = () => {
	const params = useParams();
	const { spayee_id } = params;
	const [date, setDate] = useState("");
	const [messages, setMessages] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [userId, setUserId] = useState(null);
	const [message, setMessage] = useState();
	const [editing, setEditing] = useState(false);
	const ref = useRef(null);
	async function fetchUser() {
		getSpayeeUserCourses(spayee_id)
			.then((res) => {
				const data = res.data?.data[0];
				if (!data) throw "User not found!";
				const { user_id } = data;
				setUserId(user_id);
			})
			.catch(alert);
	}

	const appendMessage = () => {
		if (!message || !message.trim()) return;
		const currMsg = messages.find((i) => isSameDay(i.date, date));
		if (currMsg) {
			currMsg.message = editing ? message : currMsg.message + "\n" + message;
			handleSubmit(currMsg.message, currMsg.date);
			setMessages([...messages]); // Trigger re-render
		} else {
			handleSubmit(message, date || Date.now());
			setMessages([...messages, { message, date }]);
		}

		setEditing(false);
		setMessage("");
		setDate("");
	};

	const onDateSelect = (e) => {
		const selectedDate = new Date(e).getTime();
		setDate(selectedDate);
		setIsVisible(false);
	};

	useEffect(() => {
		if (!userId) {
			fetchUser();
			return;
		}
		getUserAttendanceStatus(userId).then((res) => {
			if (res.success) {
				const msgs = res.data.tracking_data;
				setMessages(msgs.map((itm) => ({ message: itm.message, date: itm.date * 1000 })));
			}
		});
	}, [userId]);

	const handleSubmit = (message, date) => {
		if (!message.trim()) {
			alert("Message Can't be empty!");
			return;
		}
		const dateInSeconds = new Date(date).getTime() / 1000;
		updateUserAttendanceStatus(userId, message, dateInSeconds)
			.then(() => {
				fetchUser();
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (typeof window != undefined) {
			window.alert = () => {};
		}
	}, []);

	return (
		<div className="flex-container">
			<header className="backlog-header">
				<Header title="My Diary" />
			</header>
			<main className="backlog-main p-0 text-start">
				<div className="mx-auto mobile-view" style={{ maxWidth: "unset" }}>
					<div className="mt-3 mx-auto" style={{ maxWidth: 530 }}>
						{messages
							.sort((a, b) => a.date - b.date)
							.map(({ message, date }, index) => (
								<div
									className="mx-auto"
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										marginBottom: 10,
										marginTop: 5,
										fontFamily: "Lato",
										width: "95%",
										backgroundColor: "#5F46E3",
										paddingTop: 8,
										paddingBottom: 8,
										paddingLeft: 16,
										paddingRight: 10,
										borderRadius: 12,
									}}
									key={index}
								>
									<div
										style={{
											color: "white",
											fontSize: 16,
											width: "100%",
											lineHeight: "20px",
										}}
										dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br/>") }}
									></div>
									<span
										className="ms-auto"
										style={{
											color: "#f9f9f9",
											fontFamily: "Lato-Bold",
											paddingRight: 8,
											fontSize: 12,
											marginTop: 2,
											display: "flex",
											alignItems: "center",
											gap: 5,
										}}
									>
										{new Date(date || Date.now()).toLocaleString("en-US", {
											day: "numeric",
											month: "short",
											year: "2-digit",
										})}
										<MdModeEdit
											size={16}
											onClick={() => {
												setEditing(true);
												setDate(date);
												setMessage(message);
												ref.current?.focus();
											}}
										/>
									</span>
								</div>
							))}
					</div>
				</div>
			</main>
			<div className="chat-input-container">
				{!date ? (
					<LuCalendarDays className="calendar-icon" size={22} onClick={() => setIsVisible(!isVisible)} />
				) : (
					<span className="calendar-icon col-1" onClick={() => setIsVisible(!isVisible)}>
						{formatDateInDDMM(date)}
					</span>
				)}
				<div className="input-container">
					<textarea
						rows={getInputHeight(message)}
						ref={ref}
						name={"message"}
						className="txt-area"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						placeholder="Type a message..."
					/>
				</div>
				<IoSend size={22} className="send-icon" onClick={appendMessage} />
				<AnimatePresence>
					{isVisible && (
						<motion.div
							transition={{ duration: 0.2, bounce: 0.4 }}
							variants={variants}
							initial="hidden"
							animate="visible"
							exit="hidden"
							className="custom-calendar"
							onClick={(e) => {
								if (e.target?.className === "custom-calendar") {
									setIsVisible(false);
								}
							}}
						>
							<Calendar
								className={"text-dark"}
								onChange={onDateSelect}
								value={date}
								maxDate={new Date(Date.now())}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default UserAttendance;

import { useLocation, useNavigate } from "react-router-dom";
import PrepometerProvider from "./context.prepometer";
import Deadlines from "./deadlines.prepometer";

const DeadlineIndex = () => {
	const params = useLocation();
	const navigate = useNavigate();
	const spayee_id = params.state?.spayee_id;
	if (!spayee_id) {
		navigate("/user", { replace: true });
		return;
	}
	return (
		<PrepometerProvider spayee_id={spayee_id}>
			<Deadlines />
		</PrepometerProvider>
	);
};

export default DeadlineIndex;

import { useNavigate } from "react-router-dom";
import Header from "../backlogManager/backlog.header";
import { usePrepometer } from "./context.prepometer";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "lottie-react";
import CustomCheckbox from "../../components/common/CustomCheckbox";
import { useEffect, useState } from "react";
import celebrationArr from "../../utils/images/lottie/celebration";

const variants = {
	visible: { opacity: 1, scaleY: 1 },
	hidden: { opacity: 0, scaleY: 1 },
	exit: {
		transition: {
			duration: 1.2,
		},
		rotateZ: 180,
		y: 650,
		opacity: 0.5,
		position: "fixed",
		width: "100%",
		zIndex: 9999,
	},
};

const getBgColor = (timeStamp) => {
	let timeInMillis = new Date(timeStamp * 1000).getTime();
	let today = new Date().getTime();
	let diff = Math.round((timeInMillis - today) / (1000 * 3600 * 24));
	if (diff < 0)
		return {
			color: "#CC2C2C",
			border: `1px solid rgba(204, 44, 44,0.35)`,
		};
	else if (diff == 0)
		return {
			color: "#FFB023",
			border: `1px solid rgba(255, 176, 35,0.35)`,
		};
	return {
		color: "#000",
		border: `1px solid rgba(0,0,0,0.35)`,
	};
};

const DeadlineItem = ({ name, chapter, isTheory, isCompleted, deadline, ...props }) => {
	const { updateItem } = usePrepometer();
	const [date, setDate] = useState(deadline);
	const [checked, setChecked] = useState(isCompleted);
	const [anim, setAnim] = useState(false);

	const onAnimationComplete = () => {
		setAnim(false);
	};

	const onCheckClick = (isChecked) => {
		updateItem({
			...props,
			status: isChecked ? 1 : 0,
		});
		setChecked(isChecked);
		if (isChecked) setAnim(true);
	};

	useEffect(() => {
		if (typeof deadline == "number") {
			let tempDate = new Date(deadline * 1000)
				.toLocaleDateString("en-GB")
				.split("/")
				.slice(0, 2)
				.join("/");
			setDate(tempDate);
		}
	}, []);

	const { color } = getBgColor(deadline);
	return (
		<>
			<motion.div
				className="p-3 my-3"
				initial="hidden"
				animate="visible"
				transition={{ duration: 0.2 }}
				variants={variants}
				key={props.prepometer_chapter_item_id}
				exit="exit"
				style={{
					width: "99%",
					...getBgColor(deadline),
					backgroundColor: "#f9f9f9",
					borderRadius: 10,
				}}
			>
				<p
					className={` h6 text-center pb-2`}
					style={{
						borderBottom: `1px solid ${color}`,
					}}
				>
					{chapter}
				</p>
				<div className="d-flex align-items-center flex-wrap row">
					<span className={`col-4`}>{name}</span>
					{!checked && <span className="col-4 text-center">{date}</span>}
					<CustomCheckbox
						key={name}
						className="col-2 ms-auto"
						value={isCompleted}
						onCheck={onCheckClick}
					/>
				</div>
			</motion.div>

			{anim && (
				<div className="lootie-div">
					<Lottie
						className="lootie-main"
						animationData={celebrationArr[Math.floor(Math.random() * celebrationArr.length)]}
						onLoopComplete={onAnimationComplete}
						onClick={onAnimationComplete}
					/>
				</div>
			)}
		</>
	);
};

const Deadlines = () => {
	const { subjects } = usePrepometer();
	const navigate = useNavigate();
	const onBackClick = () => {
		navigate(-1);
	};
	const deadlineItems = [];
	subjects.map((ch) =>
		ch.chapters.filter((el) =>
			el.items.map((i) => {
				if (i.deadline && i.status == 0) {
					deadlineItems.push({ ...i, chapter: el.display_name });
				}
			})
		)
	);

	return (
		<div className="flex-container">
			<header className="backlog-header">
				<Header title="My Targets" onBackClick={onBackClick} />
			</header>
			<main className="backlog-main p-0 text-start mb-0">
				<div className="ps-2 pt-2 mobile-view mx-auto">
					<AnimatePresence>
						{deadlineItems
							.sort((a, b) => a.deadline - b.deadline)
							.map(
								(item) =>
									item.deadline &&
									item.status == 0 && (
										<DeadlineItem
											name={item.name}
											chapter={item.chapter}
											isCompleted={false}
											isTheory={true}
											key={item.prepometer_chapter_item_id}
											deadline={item.deadline}
											{...item}
										/>
									)
							)}
					</AnimatePresence>
				</div>
			</main>
		</div>
	);
};

export default Deadlines;

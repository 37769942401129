import React, { useState } from "react";
import PrepometerCard from "./card.prepometer";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { usePrepometer } from "./context.prepometer";
import NewButton from "../../components/NewButton";

const PrepometerSubject = ({ chapters, id }) => {
	const { updateChapterStatus, isFiltered, setIsFiltered } = usePrepometer();
	const [idsToAdd, setIdsToAdd] = useState([]);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(!show);

	const addChapter = (id) => {
		if (idsToAdd.includes(id)) {
			let newArr = idsToAdd.filter((itm) => itm !== id);
			setIdsToAdd(newArr);
		} else {
			setIdsToAdd((prev) => [...prev, id]);
		}
	};

	const isAdded = (id) => {
		return idsToAdd.includes(id);
	};

	const addChapters = () => {
		updateChapterStatus(idsToAdd);
		handleShow();
	};
	const chaptersCompleted = [];
	const chaptersUnCompleted = [];
	const chaptersToAdd = [];

	for (let ch of chapters) {
		if (ch.items.length == 0) {
			chaptersToAdd.push(ch);
			continue;
		} else if (ch.items.every((i) => i.status != 0)) {
			chaptersCompleted.push(ch);
			continue;
		} else chaptersUnCompleted.push(ch);
	}

	const chaptersToShow = [...chaptersUnCompleted, ...chaptersCompleted];

	return (
		<div className="ps-2">
			<Form.Check
				key={id}
				type="switch"
				id="custom-switch"
				label="Hide Completed"
				className="my-2 mx-2 float-end w-100"
				onChange={(e) => setIsFiltered(e.target.checked)}
				checked={isFiltered}
				reverse
			/>
			{chaptersToShow.map((chapter, i) => (
				<PrepometerCard
					chapter={chapter.display_name}
					progress={chapter.progress}
					items={chapter.items}
					key={chapter.node_id}
					id={chapter.node_id}
				/>
			))}
			<Offcanvas placement="bottom" show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title className="text-center">Select Chapters</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<ul className="p-2 pt-0">
						{chaptersToAdd.map((itm) => (
							<li className="row m-2 align-items-center justify-content-center" key={itm.node_id}>
								<NewButton
									key={itm.node_id}
									selectable={true}
									className={`w-75 py-2`}
									selected={isAdded(itm.node_link_id)}
									onClick={() => addChapter(itm.node_link_id)}
								>
									{itm.display_name}
								</NewButton>
							</li>
						))}
						<div className="btn-container-fixed">
							<Button className="new-btn-outline w-75 py-2 bg-primary" onClick={addChapters}>
								Done
							</Button>
						</div>
					</ul>
				</Offcanvas.Body>
			</Offcanvas>
			<div className="backlog-footer">
				<NewButton className="new-btn-outline w-100 py-2 bg-light" onClick={handleShow}>
					Add Chapter
				</NewButton>
			</div>
		</div>
	);
};

export default PrepometerSubject;

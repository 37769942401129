import React, { memo } from "react";
import PrepoMeterItem from "./item.prepometer";
import Card from "react-bootstrap/Card";
import { ListGroup } from "react-bootstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import PrepometerMenu from "./menu.prepometer";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
	visible: { opacity: 1, y: 0, scale: 1 },
	hidden: { opacity: 0, y: 200, scale: 1 },
};

const PrepometerCard = memo(({ chapter, items, id }) => {
	// const vairantType = progress < 20 ? "danger" : progress < 40 ? "warning" : progress < 75 ? "primary" : "success";
	const available = items.filter((itm) => !itm.is_hidden && itm.status != -1);
	const firstChecked = available.find((itm) => itm.name == "Theory")?.status == 1;
	return (
		<motion.div
			layout
			key={id}
			initial="hidden"
			animate="visible"
			transition={{ duration: 0.4, bounce: 0.2, staggerChildren: 0.1 }}
			variants={variants}
			className="mb-2 card bg-light custom-shadow"
		>
			<Card.Body className="p-2">
				<Card.Title className="card-title p-2 mb-1 row align-items-center">
					<span className="col-11">{chapter}</span>
					<PrepometerMenu assets={items} />
				</Card.Title>
				{/* <div className="px-1 d-flex my-1 align-items-center">
                    <ProgressBar animated variant={vairantType} className="float-start" style={{ height: 8, width: '90%' }} now={progress} />
                    <i className="float-end ps-1">{progress || 0}%</i>
                </div> */}
				<ListGroup horizontal className="gap-1 d-flex flex-wrap">
					<AnimatePresence>
						{available.map((itm, i) => {
							let color = firstChecked && itm.name !== "Theory" && itm.status != 1 ? "#6f0b0b" : null;
							return (
								<PrepoMeterItem
									{...itm}
									color={color}
									isCompleted={itm.status == 1}
									chapter={chapter}
									key={itm.prepometer_chapter_item_id}
									isFirst={i == 0 && itm.name == "Theory"}
								/>
							);
						})}
					</AnimatePresence>
				</ListGroup>
			</Card.Body>
		</motion.div>
	);
});

export default PrepometerCard;

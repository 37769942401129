import React from "react";
import PrepometerTabs from "./tabs.prepometer";
import PrepometerProvider from "./context.prepometer";
import Header from "../backlogManager/backlog.header";
import { LuCalendarDays } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";

const Prepometer = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { spayee_id } = params;
	const onIconClick = () => {
		navigate("/user/targets", { state: { spayee_id } });
	};

	return (
		<PrepometerProvider spayee_id={spayee_id}>
			<div className="flex-container">
				<header className="backlog-header">
					<Header
						title="Prepometer 2.0"
						onBackClick={() => {}}
						leftIcon={<LuCalendarDays size={24} onClick={onIconClick} />}
					/>
				</header>
				<main className="backlog-main p-0 text-start">
					<div className="mx-auto mobile-view">
						<PrepometerTabs />
					</div>
				</main>
			</div>
		</PrepometerProvider>
	);
};

export default Prepometer;

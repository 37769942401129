import React from "react";


const NewButton = ({selectable,selected ,className, ...props}) => {
    let customClass = selectable  && selected ? "selected-btn" : "unSelected-btn";
    return(
        <button className={`${className} ${customClass}`} {...props}>
            {props.children}
        </button>
    )
}

export default NewButton;